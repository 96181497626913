import React from 'react';

import { BUTTON_STYLES } from '../../constants/ui';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { tw } from '../../utils/tailwind';
import { Box, Grid, Stack } from '../Core';
import ImageCard from '../ImageCard';
import SectionWithTitle from '../SectionWithTitle';
import StyledLink from '../StyledLink';
import ThemedButton from '../ThemedButton';

interface IServicesSectionProps {
  description?: string;
  services?: any;
  title?: string;
  ctaAllServices?: readonly {
    readonly externalLink: string;
    readonly style: string;
    readonly titre: string;
    readonly internalLink: { readonly id: string; readonly uri: string };
  }[];
}

const ServicesSection: FCWithClassName<IServicesSectionProps> = (props) => {
  const { className, ctaAllServices, description, services, title } = props;
  return (
    <SectionWithTitle
      heading={title}
      text={description}
      contentAlignment="center"
      className={tw('relative', className)}
    >
      <Box className="absolute -right-[10%] bottom-[5%] lg:bottom-[10%] xl:bottom-[20%] w-[70%] h-[400px] bg-lightningYellow" />
      <Stack className="w-full space-y-20" align="center">
        {/* {sectionCtas?.map((cta) => (
          <StyledLink
            key={cta.titre}
            to={cta.internalLink?.uri}
            href={cta.externalLink ?? undefined}
          >
            <ThemedButton variant="link">{cta.titre}</ThemedButton>
          </StyledLink>
        ))} */}
        <Grid className="grid-cols-1 sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-5 gap-y-4 gap-x-12 w-full justify-items-start ">
          {services?.map((service: any) => {
            if (!service) return null;
            return (
              <ImageCard
                key={service.id}
                image={serializeImageForImageComponent({
                  image: service.featuredImage?.node, // TODO : use the square image when available
                })}
                title={service.title}
                text={service.informationsService.shortDescription}
                color={service.informationsService.couleur}
                cta={{
                  label: 'En savoir plus',
                  style: BUTTON_STYLES.SOLID,
                  to: service.uri,
                }}
                //  fontSize="20px"
              />
            );
          })}
        </Grid>

        {ctaAllServices?.map((cta: any) => (
          <StyledLink
            key={cta.titre}
            to={cta.internalLink?.uri}
            href={cta.externalLink ?? undefined}
          >
            <ThemedButton>{cta.titre}</ThemedButton>
          </StyledLink>
        ))}
      </Stack>
    </SectionWithTitle>
  );
};

export default ServicesSection;
