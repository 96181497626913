import './style.css';

import React from 'react';

import CarouselContainer from '../Carousel/CarouselContainer';
import { Box } from '../Core';
import { Flex } from '../Core/Flex';
import SectionWithTitle from '../SectionWithTitle';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

export interface ITestimonial {
  nom: string;
  situation: string;
  description: string;
}

interface ITestimonialsSectionProps {
  description: string | undefined;
  title: string | undefined;
  testimonials: readonly (ITestimonial | undefined)[];
}

const TestimonialsSection: FCWithClassName<ITestimonialsSectionProps> = (
  props
) => {
  const { description, title, testimonials } = props;
  if (!testimonials) return null;
  const slides = testimonials.map(
    (testimonial: ITestimonial | undefined, index: number) => {
      if (!testimonial) return undefined;
      return (
        <Flex
          key={testimonial?.nom + index}
          direction="column"
          align="center"
          className="text-center"
        >
          <Text variant={TEXT_STYLES.H5} className="font-bold">
            {testimonial.nom}
          </Text>
          <Text>{testimonial.situation}</Text>
          <Text className="italic">{testimonial.description}</Text>
        </Flex>
      );
    }
  );
  const leftDoubleQuotationmark = '&#8220;';
  const rightDoubleQuotationmark = '&#8221;';
  return (
    <SectionWithTitle heading={title} text={description}>
      <Box className="relative z-10 w-full">
        <Text className="!font-arial !text-[300px] md:!text-[500px] text-primaryMain absolute -z-10 top-12 xl:top-32 xl:left-[20%] !leading-[0.1] opacity-40">
          {leftDoubleQuotationmark}
        </Text>
        <Text className="!font-arial !text-[300px] md:!text-[500px] text-primaryMain absolute z-0 top-80 xl:top-72 xl:right-[20%] !leading-[0.1] opacity-40">
          {rightDoubleQuotationmark}
        </Text>
        <CarouselContainer
          interval={5000}
          dots="under"
          className="w-[335px] md:w-[500px] xl:w-[800px] mx-auto my-0"
        >
          {slides}
        </CarouselContainer>
      </Box>
    </SectionWithTitle>
  );
};

export default TestimonialsSection;
