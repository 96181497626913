import React from 'react';

import { tw } from '../../utils/tailwind';
import SpinnerSVG from '../SVG/SpinnerSVG';

export interface ISpinnerProps {
  color: string;
}

export const Spinner: FCWithClassName<ISpinnerProps> = ({
  className,
  color,
}) => {
  return (
    <div className={tw('inline-block leading-none text-center', className)}>
      <SpinnerSVG color={color} />
    </div>
  );
};

export default Spinner;
