import React from 'react';

import { tw } from '../../utils/tailwind';
import { Box, Flex } from '../Core';
import Image from '../Image';
import { IImage } from '../Image/Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

interface IPostEntryProps {
  categoryName: string;
  ctaLabel: string;
  color?: string | null;
  image: IImage | null;
  title: string;
  url: string;
}

const PostEntry: FCWithClassName<IPostEntryProps> = (props) => {
  const { categoryName, ctaLabel, color = 'white', image, title, url } = props;

  const desktopComponent = (
    <Flex
      justify="flex-end"
      align="center"
      style={{ background: color }}
      className={tw(
        'relative hidden lg:flex py-12 px-4 w-[500px] h-80',
        props.className
      )}
    >
      <Box className="flex-[2]" />
      <Image image={image} className="absolute top-0 z-10 -left-10" />
      <Text
        variant={TEXT_STYLES.H3}
        className="absolute -top-[0.5em] -left-[0.3em] !font-bold z-20"
      >
        {categoryName}
      </Text>

      <Text
        variant={TEXT_STYLES.H5}
        className="text-white uppercase !font-bold text-center flex-[1.8]"
      >
        {title}
      </Text>
      <StyledLink
        key={ctaLabel}
        to={url}
        className="!absolute bottom-[-30px] right-[45px]"
      >
        <ThemedButton className="whitespace-nowrap">{ctaLabel}</ThemedButton>
      </StyledLink>
    </Flex>
  );
  const mobileComponent = (
    <Flex
      className={tw(
        'relative items-center justify-center w-full h-full lg:hidden',
        props.className
      )}
    >
      <Image image={image} className="w-full h-full aspect-w-1 aspect-h-1" imageClassName="!absolute"/>
      <Text
        variant={TEXT_STYLES.H3}
        className="!font-bold absolute top-[-0.5em] left-[-0.3em]"
      >
        {categoryName}
      </Text>
      <Box className="absolute flex justify-center">
        <Flex
          align="center"
          justify="center"
          style={{ background: color }}
          className="relative w-10/12 p-12 m-12"
        >
          <Text
            variant={TEXT_STYLES.H5}
            className="font-bold text-center text-white uppercase"
          >
            {title}
          </Text>
          <StyledLink
            key={ctaLabel}
            to={url}
            className="absolute -bottom-[30px]  right-[50%] translate-x-1/2"
          >
            <ThemedButton className="whitespace-nowrap">{ctaLabel}</ThemedButton>
          </StyledLink>
        </Flex>
      </Box>
    </Flex>
  );
  return (
    <>
      {desktopComponent}
      {mobileComponent}
    </>
  );
};

export default PostEntry;
