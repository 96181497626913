import React from 'react';

import { BUTTON_STYLES } from '../../constants/ui';
import { IWordpressCTA } from '../../types/wordpress/blocks';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { tw } from '../../utils/tailwind';
import { Box, Grid, Stack } from '../Core';
import ImageCard from '../ImageCard';
import SectionWithTitle from '../SectionWithTitle';
import StyledLink from '../StyledLink';
import ThemedButton from '../ThemedButton';

interface IThematiquesSectionProps {
  ctaLabel?: string;
  description?: string;
  sectionCtas?: IWordpressCTA[];
  thematiques?: Queries.PageThematiquesQuery['allWpThematique']['nodes'];
  title?: string;
  ctaAllServices?: readonly { readonly externalLink: string; readonly style: string; readonly titre: string; readonly internalLink: { readonly id: string; readonly uri: string; }; }[];
}

const ThematiquesSection: FCWithClassName<IThematiquesSectionProps> = (
  props
) => {
  const { ctaLabel, description, sectionCtas, thematiques, title } = props;
  return (
    <SectionWithTitle
      heading={title}
      text={description}
      contentAlignment="center"
      className={tw('relative', props.className)}
    >
      <Box className="absolute right-[-10%] bottom-[15%] w-[70%] h-96 bg-primaryMain" />
      <Stack align="center" className="w-full space-y-20">
        {sectionCtas?.map((cta) => (
          <StyledLink
            key={cta.titre}
            to={cta.internalLink?.uri}
            href={cta.externalLink ?? undefined}
            anchorId={cta.anchor}
          >
            <ThemedButton>{cta.titre}</ThemedButton>
          </StyledLink>
        ))}
        <Grid
          className={tw(
            thematiques.length > 2
              ? 'grid-cols-[repeat(auto-fit,minmax(300px,1fr))]'
              : 'grid-cols-[repeat(auto-fit,minmax(300px,420px))]',
            'justify-items-center justify-center w-full gap-8'
          )}
        >
          {thematiques?.map((thematique) => {
            if (!thematique) return null;
            return (
              <ImageCard
                key={thematique.informations.titre}
                image={serializeImageForImageComponent({
                  image: thematique.featuredImage?.node, // TODO : use the square image when available
                })}
                title={thematique.informations.titre}
                text={thematique.informations.shortDescription}
                color={thematique.informations.couleur}
                cta={{
                  label: ctaLabel,
                  style: BUTTON_STYLES.SOLID,
                  to: thematique.uri,
                }}
                frontTextClassName="!text-6 lg:!text-6"
              />
            );
          })}
        </Grid>
        {props.ctaAllServices?.map((cta) => (
          <StyledLink
            key={cta.titre}
            to={cta.internalLink?.uri}
            href={cta.externalLink ?? undefined}
          >
            <ThemedButton>{cta.titre}</ThemedButton>
          </StyledLink>
        ))}
      </Stack>
    </SectionWithTitle>
  );
};

export default ThematiquesSection;
