import React from 'react';

import { DEFAULT_LANGUAGE } from '../../constants/global';
import { FILTERS_NO_RESULTS } from '../../constants/messages';
import { IWordpressPathologieEntry } from '../../types/wordpress/pathologie';
import { IWordpressPostEntry } from '../../types/wordpress/post';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { Flex } from '../Core/Flex';
import PostEntry from '../PostEntry/PostEntry';
import SectionWithTitle from '../SectionWithTitle';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IPostsSectionProps {
  currentLanguageCode: Partial<CountryCode>;
  description?: string | undefined;
  displayedCategoriesSlugs?: string[];
  noPostsMessage?: string;
  title?: string | undefined;
  posts?: Queries.PageBlogQuery['allWpPost']['nodes'];
}

const PostsSection: FCWithClassName<IPostsSectionProps> = (props) => {
  const {
    currentLanguageCode,
    description,
    displayedCategoriesSlugs,
    noPostsMessage,
    posts,
    title,
    ...rest
  } = props;
  const getCategoryMatchingActiveFilters = ({
    postCategories,
    activeFilters,
    zonesCorps,
  }: {
    activeFilters: string[] | undefined;
    postCategories?: IWordpressPostEntry['categories']['nodes'];
    zonesCorps?: IWordpressPathologieEntry['zonesCorps']['nodes'];
  }) => {
    const categories = postCategories ?? zonesCorps;
    if (!activeFilters?.length) return categories?.[0];
    const categoryMatchingActiveFilter = categories.find((category) =>
      activeFilters.includes(category.slug)
    );
    return categoryMatchingActiveFilter;
  };
  const displayPostEntryForPost = (post: IWordpressPostEntry) => {
    const categoryMatchingActiveFilters =
      post?.categories.nodes &&
      getCategoryMatchingActiveFilters({
        activeFilters: displayedCategoriesSlugs,
        postCategories: post?.categories.nodes,
      });
    if (!post || !categoryMatchingActiveFilters) return undefined;
    return (
      <PostEntry
        categoryName={categoryMatchingActiveFilters.name}
        color={categoryMatchingActiveFilters.categoryInformations.couleur}
        ctaLabel={currentLanguageCode === 'FR' ? 'Lire la suite' : 'Read more'} // todo : fix
        image={serializeImageForImageComponent({
          image:
            post.informationsPost?.sectionHero.imageMobile ??
            post.featuredImage?.node,
        })}
        key={post.id}
        title={post.title}
        url={post.uri}
      />
    );
  };
  return (
    <SectionWithTitle heading={title} text={description} {...rest}>
      {posts?.length === 0 && (
        <Text variant={TEXT_STYLES.H5} className="pt-12 mx-auto text-center">
          {noPostsMessage ??
            FILTERS_NO_RESULTS[currentLanguageCode] ??
            FILTERS_NO_RESULTS[DEFAULT_LANGUAGE]}
        </Text>
      )}
      <Flex
        className="w-full gap-16 lg:gap-y-32 lg:flex-wrap"
        direction={{ base: 'column', lg: 'row' }}
        justify={{ base: 'flex-start', lg: 'space-around' }}
      >
        {posts?.map(displayPostEntryForPost)}
      </Flex>
    </SectionWithTitle>
  );
};

export default PostsSection;
