import './accueil.css';

import { graphql, PageProps } from 'gatsby';
import React from 'react';
import LazyLoad from 'react-lazyload';

import CentresSection from '../../components/CentresSection';
import { Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import PostsSection from '../../components/PostsSection/PostsSection';
import SEO from '../../components/SEO';
import TestimonialsSection from '../../components/TestimonialsSection';
import ThematiquesSection from '../../components/ThematiquesSection/ThematiquesSection';
import { IWordpressImage } from '../../types/wordpress/blocks';
import shuffle from '../../utils/helpers/shuffle';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';
import KinequickForm from '../components/Accueil/KinequickForm';
import ServicesSection from '../../components/ServiceSections/ServiceSections';

export interface ThematiqueDisplayData {
  informations: {
    titre?: string;
    couleur: string;
    shortDescription: string;
    temoignages: {
      description?: string;
      nom?: string;
      situation?: string;
    };
  };
  id: string;
  uri: string;
  featuredImage: {
    node: IWordpressImage;
  };
}

export type HomepageProps = PageProps<Queries.PageDAccueilQuery, IPageContext>;

const HomepageTemplate: FCWithClassName<HomepageProps> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData =
    'contenuHomepage' in data.wpPage.template
      ? data.wpPage.template.contenuHomepage
      : null;
  if (
    !pageData ||
    !pageData?.link ||
    !pageContext.defaultLanguage ||
    !pageTemplateData
  )
    return null;
  const formulaireRecherche =
    'formulaireRechercheCentre' in pageData.template
      ? pageData.template.formulaireRechercheCentre
      : null;
  const centresDisplayData = data.allWpCentre.nodes;
  const servicesDataForDropdown = data.allWpService.nodes;
  const missions = data.allWpThematique.nodes;
  const firstHeroSlide = pageTemplateData.carroussel?.[0];
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: firstHeroSlide?.image,
    mobileImage: firstHeroSlide?.imageMobile,
  });
  const hero = firstHeroSlide && (
    <Hero
      couleurTitre="#fff"
      heading={firstHeroSlide?.titre}
      tagline={firstHeroSlide?.sousTitre}
      backgroundImage={heroImageWithArtDirection}
      type={HERO_TYPE.STATIC}
      overlay
      kinequickForm={
        <KinequickForm
          data={data.configurations?.nodes[0]?.configuration?.linksForRdv}
          servicePlaceholder={
            formulaireRecherche.formulaireRechercheDeCentre?.service
          }
          centrePlaceholder="Centres"
          cta={pageTemplateData.ctaCarroussel?.[0]}
          ctaTelephone={pageTemplateData.ctaTelephone}
          lang={pageContext.currentLanguage.code}
          customLabelRDV="Prendre rendez-vous en ligne"
        />
      }
    />
  );
  const contenuSectionServices = pageTemplateData.sectionServices;

  const sectionServices = contenuSectionServices.titre && (
    <ServicesSection
      services={contenuSectionServices.servicesRelations}
      description={contenuSectionServices.description}
      title={contenuSectionServices.titre}
      // ctaLabel={contenuSectionServices.thematiqueButtonLabel}
      // sectionCtas={contenuSectionServices.cta ?? undefined}
      ctaAllServices={contenuSectionServices.linksCta ?? undefined}
    />
  );

  const contenuSectionMissions = pageTemplateData.sectionPhilosophie;
  const sectionMissions = contenuSectionMissions?.titre && (
    <ThematiquesSection
      ctaLabel={contenuSectionMissions.cta[0].titre}
      thematiques={missions}
      description={contenuSectionMissions?.description}
      title={contenuSectionMissions?.titre}
      ctaAllServices={contenuSectionMissions.ctaAllServices}
    />
  );
  const contenuSectionCentres = pageTemplateData.sectionCentres;
  const sectionCentres = (
    <CentresSection
      id="centres"
      centres={centresDisplayData}
      sectionData={{
        ...contenuSectionCentres,
        searchFormLabels: formulaireRecherche.formulaireRechercheDeCentre,
      }}
      services={servicesDataForDropdown}
    />
  );
  const contenuSectionPosts = pageTemplateData.sectionPosts;
  const sectionPosts = (
    <PostsSection
      currentLanguageCode={pageContext.currentLanguage.code}
      title={contenuSectionPosts.titre}
      description={contenuSectionPosts.description}
      posts={[
        data.nutritionPost.nodes[0],
        data.performancePost.nodes[0],
        data.healthyPost.nodes[0],
      ]}
    />
  );
  const testimonials = data.allWpThematique?.nodes?.flatMap(
    (thematique) => thematique?.informations.temoignages
  ) as (
    | {
        nom: string;
        situation: string;
        description: string;
      }
    | undefined
  )[];
  const displayedTestimonials = shuffle(testimonials).slice(0, 5);
  const contenuSectionTestimonials = pageTemplateData.sectionTemoignages;
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap">
        {hero}
        {sectionServices}
        {sectionMissions}
        {sectionCentres}
        {sectionPosts}
        <LazyLoad offset={1000} height={1000} once>
          <TestimonialsSection
            testimonials={displayedTestimonials}
            description={contenuSectionTestimonials.description}
            title={contenuSectionTestimonials.titre}
          />
        </LazyLoad>
      </Stack>
    </Layout>
  );
};

export default HomepageTemplate;

export const query = graphql`
  fragment ThematiqueDisplayData on WpThematique {
    informations {
      titre
      couleur
      shortDescription
      temoignages {
        ... on WpThematique_Informations_Temoignages_Temoignage {
          description
          nom
          situation
        }
      }
    }
    id
    uri
    featuredImage {
      node {
        ...MediaItemFragment
        localFile {
          childImageSharp {
            gatsbyImageData(width: 430, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }

  query PageDAccueil($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
            publicURL
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      translations {
        language {
          code
          locale
        }
        uri
      }
      template {
        ... on WpTemplate_PageDaccueil {
          contenuHomepage {
            carroussel {
              titre
              sousTitre
              image {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                }
              }
              imageMobile {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      breakpoints: [340, 400, 600, 800]

                      layout: FULL_WIDTH
                    )
                  }
                  publicURL
                }
              }
            }
            ctaCarroussel {
              ... on WpTemplate_PageDaccueil_Contenuhomepage_CtaCarroussel_Cta {
                anchor
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
            }
            ctaTelephone {
              label
              telephone
            }
            sectionCentres {
              description
              image {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                }
              }
              titre
            }
            sectionPosts {
              description
              titre
              cta {
                externalLink
                internalLink {
                  ... on WpPost {
                    id
                    uri
                  }
                }
                style
                titre
              }
            }
            sectionPhilosophie {
              titre
              description
              cta {
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
              thematiqueButtonLabel
              ctaAllServices {
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
              services {
                ... on WpService {
                  id
                  featuredImage {
                    node {
                      ...MediaItemFragment
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 400, srcSetBreakpoints: [400, 800]) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  title
                  uri
                  informationsService {
                    couleur
                    shortDescription
                    kinequick {
                      type
                      speciality
                    }
                  }
                }
              }
            }
            sectionServices {
              titre
              description
              linksCta {
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
              servicesRelations {
                ... on WpService {
                  id
                  featuredImage {
                    node {
                      ...MediaItemFragment
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 400, srcSetBreakpoints: [400, 800]) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  title
                  uri
                  informationsService {
                    couleur
                    shortDescription
                    kinequick {
                      type
                      speciality
                    }
                  }
                }
              }
            }
            sectionTemoignages {
              titre
              description
            }
          }
          formulaireRechercheCentre {
            formulaireRechercheDeCentre {
              adresse
              centreLePlusProche
              geolocalisation
              service
              tousServices
              messageErreurGeolocalisation
              pageDeResultats {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
      }
    }
    allWpCentre(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        title
        id
        uri
        slug
        coordonnees {
          adresse
          gps
          telephone
          lienRdv
        }
      }
    }
    nutritionPost: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: 1
      filter: {
        categories: {
          nodes: {
            elemMatch: { slug: { regex: "/(alimentation|nutrition)/" } }
          }
        }
        language: { slug: { eq: $currentLanguageSlug } }
      }
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
    performancePost: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: 1
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "performance" } } } }
        language: { slug: { eq: $currentLanguageSlug } }
      }
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
    healthyPost: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: 1
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "healthy" } } } }
        language: { slug: { eq: $currentLanguageSlug } }
      }
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
    allWpService(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        id
        slug
        title
        informationsService {
          kinequick {
            type
            speciality
          }
        }
        uri
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          textes {
            voirPlus
          }
        }
      }
    }
    configurations: allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          linksForRdv {
            target
            url
            service {
              ... on WpService {
                title
                slug
              }
            }
            place {
              ... on WpCentre {
                title
                slug
              }
            }
          }
        }
      }
    }
    allWpThematique(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: informations___displayOrder, order: ASC }
    ) {
      nodes {
        ...ThematiqueDisplayData
      }
    }
  }
`;
