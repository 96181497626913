const shuffle = <T>(array: T[]) => {
  const arr2 = array.concat();
  for (let i = arr2.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = arr2[i];
    arr2[i] = arr2[j];
    arr2[j] = temp;
  }
  return arr2;
}

export default shuffle;