export const BUTTON_STYLES = {
  GHOST: 'ghost',
  LINK: 'link',
  OUTLINE: 'outline',
  SOLID: 'solid',
  UNSTYLED: 'unstyled',
} as const;

export type ButtonStyles = typeof BUTTON_STYLES[keyof typeof BUTTON_STYLES];

export const BUTTON_TARGET = {
  BLANK: '_blank',
  SELF: '_self',
};

export type ButtonTargetStyles =
  typeof BUTTON_TARGET[keyof typeof BUTTON_TARGET];
